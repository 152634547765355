import { mdiAccount, mdiCart, mdiCreditCard, mdiHomeOutline, mdiWallet, mdiInbox, mdiFilePdf } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
  },
  {
    title: 'Request',
    icon: mdiAccount,
    to: 'members',
  },
  {
    title: 'Cashiering',
    icon: mdiCart,
    to: 'cashiering',
  },
  {
    title: 'Report',
    icon: mdiFilePdf,
    to: 'report',
  },
]
