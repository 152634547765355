import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'universal-cookie'
import interceptors from '@/functions/request'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/cashiering',
    name: 'cashiering',
    component: () => import('@/views/Cashiering/list.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/cashiering/cart/:id?',
    name: 'cart',
    component: () => import('@/views/Cashiering/index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/Report/index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/members',
    name: 'members',
    component: () => import('@/views/Members/list.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/User/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login-v2.vue'),
    meta: {
      layout: 'blank',
    },
  },
  // {
  //   path: '/register',
  //   name: 'auth-register',
  //   component: () => import('@/views/authentication/Register-v2.vue'),
  //   meta: {
  //     layout: 'blank',
  //     resource: 'Public',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentication/ForgotPassword-v2.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/otp',
    name: 'otp',
    component: () => import('@/views/authentication/Otp.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const cookies = new Cookies()
  const accessToken = cookies.get('access_token')

  if (to.path === '/login' || to.path === '/otp' || to.path === '/forgot-password' || to.path === '/register') {
    if (accessToken !== undefined) {
      interceptors()
      next({ path: '/dashboard', replace: true })
    } else {
      next()
    }
  } else if (accessToken === undefined) {
    next({ path: '/login', replace: true })
  } else {
    interceptors()
    next()
  }
})

export default router
